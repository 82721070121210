<template>
  <div class="col-lg-4 col-sm-12 col-md-6">
    <b-modal
      :id="'duplicate-modal-' + activity.id"
      :ref="'duplicate-modal-' + activity.id"
      :title="$t('Duplicate activity')"
      hide-footer
    >
      <p class="my-1">
        {{ $t('duplicate_intro') }}
      </p>

      <b-form-group :label="$t('New activity name')" label-for="name">
        <b-form-input id="name" v-model="duplicating_name" />
      </b-form-group>
      <b-form-group>
        <b-button class="float-right" variant="primary" size="lg" @click="duplicateActivity">{{
          $t('Duplicate activity')
        }}</b-button>
      </b-form-group>
    </b-modal>

    <Modal
      :id="modalOptions.clickedId"
      :text="modalOptions.text"
      :ok-button="modalOptions.okButton"
      :cancel-button="modalOptions.cancelButton"
      :show="show"
      @result="handleModalResult"
      @hidden="show = false"
    />

    <!--ACTIVITY CARD-->
    <div class="card card-shadow">
      <b-link
        class="nav-link"
        :to="
          restrictOperations
            ? `/activities/${activity.type_id}`
            : `/activities/${activity.type_id}/edit/${activity.id}`
        "
      >
        <div class="card-body pt-0">
          <div class="row">
            <div class="col-10 mt-1">
              <h6 class="card-title bolder bolder font-small-4" style="color: #0e1a1a">
                {{ activity.name }}
              </h6>
            </div>
            <div class="col-2">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-show="activity.status !== 'draft'"
                    v-clipboard:copy="frontURL + (activity.current_slug || activity.id)"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                  >
                    <feather-icon icon="ShareIcon" class="mr-50" />
                    <span>{{ $t('Share link') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!restrictOperations"
                    :to="`/activities/${activity.type_id}/edit/${activity.id}`"
                  >
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>{{ $t('Edit') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="activity.status === 'published'"
                    @click="openUnpublishConfirm(activity.id)"
                  >
                    <feather-icon icon="DivideIcon" class="mr-50" />
                    <span>{{ $t('action-draft') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="activity.status === 'draft' && !restrictOperations"
                    @click="openPublishConfirm(activity.id)"
                  >
                    <feather-icon icon="DivideIcon" class="mr-50" />
                    <span>{{ $t('action-publish') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!restrictOperations"
                    @click="setDuplicationId(activity.id)"
                  >
                    <feather-icon icon="CopyIcon" class="mr-50" />
                    <span>{{ $t('Duplicate activity') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="openDeleteConfirm(activity.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>{{ $t('Delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
          </div>

          <b-img-lazy
            :src="
              activity.media[0]
                ? activity.media[0].original_url
                : require('@/assets/images/banner/banner-6.jpg')
            "
            fluid
            v-bind="mainProps"
            class="w-100 card-img"
          />
          <div class="row pt-1 pl-1">
            <div class="col-6">
              <img
                src="@/assets/images/icons/toddl/child.svg"
                class="float-left"
                style="padding-top: 6px"
              />
              <p class="font-small-2 bold ml-1">
                Age {{ activity.min_age }}-{{ activity.max_age }} yrs
              </p>
            </div>
            <div v-if="activity.price_options.length" class="col-6">
              <p class="font-small-2 bold ml-1 price-sum">
                <span>{{ displayPrice() + ' / ' }}</span>
                <small>{{
                  activity.price_options[0] ? activity.price_options[0].price_unit : ''
                }}</small>
              </p>
            </div>
            <div v-if="activity.status === 'published'" class="col-12">
              <a
                target="_blank"
                class="marketplace-link"
                @click="openOnMarketplace(activity.current_slug || activity.id)"
              >
                <feather-icon icon="LinkIcon" class="mr-20" />
                {{ $t('View in marketplace') }}
              </a>
              <div style="clear: both" />
            </div>
          </div>

          <!-- stats -->
          <div v-if="activity.pv.total" class="activity-stats">
            <span class="total">
              <feather-icon icon="EyeIcon" class="mr-20" />
              <strong>{{ activity.pv.total }} &nbsp; {{ $t('pageviews') }}</strong>
            </span>
            <span class="unique">
              <feather-icon icon="UsersIcon" class="mr-20" />
              <strong>{{ activity.pv.unique }} &nbsp; {{ $t('unique_users') }}</strong>
            </span>
          </div>
        </div>
      </b-link>
    </div>
  </div>
</template>
<script>
import ToastificationContent from '@/components/ToastificationContent'
import Modal from '@/components/Modal'
import ActivityService from '@/services/ActivityService'

import {
  BButton,
  BDropdownItem,
  BDropdown,
  BImgLazy,
  BLink,
  BFormGroup,
  BModal,
  BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'ActivityCard',
  components: {
    BButton,
    BDropdownItem,
    BDropdown,
    BImgLazy,
    BLink,
    BFormGroup,
    BModal,
    BFormInput,
    Modal,
  },
  props: ['activity', 'restrictOperations'],
  data: function () {
    return {
      ActivityService: new ActivityService(),
      duplicating: -1,
      duplicating_name: this.activity.name,
      show: false,
      modalOptions: {
        text: '',
        okButton: '',
        cancelButton: '',
        clickedId: 0,
        currentAction: '',
      },
      frontURL: process.env.VUE_APP_FRONT_URL + '/activity-details/',
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#F8F8F8',
        width: '100%',
        height: 200,
      },
    }
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Activity link copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy the link ☹',
          icon: 'BellIcon',
        },
      })
    },
    setDuplicationId(id) {
      this.duplicating = id
      this.$bvModal.show('duplicate-modal-' + this.activity.id)
    },
    duplicateActivity() {
      this.ActivityService.duplicateActivity({
        name: this.duplicating_name,
        activity_id: this.activity.id,
      }).then(
        res => {
          this.$bvModal.hide('duplicate-modal-' + this.activity.id)
          // reload page
          this.$router.go()
          this.getData()
        },
        error => {
          console.error(error)

          this.$bvModal.hide('duplicate-modal-' + this.activity.id)
        }
      )
    },
    openOnMarketplace: function (slug) {
      let baseURL = this.frontURL + slug
      window.open(baseURL, '_blank').focus()
    },
    displayPrice: function () {
      if (!this.activity.price_options.length) return ''
      const firstPrice = this.activity.price_options[0]
      return firstPrice.price === 0 ? this.$t('Free') : firstPrice.display_price
    },
    openUnpublishConfirm(id) {
      this.show = true
      this.modalOptions = {
        text: 'Are you sure you want to draft this Activity ?',
        okButton: 'draft',
        cancelButton: 'Cancel',
        clickedId: id,
        currentAction: 'Status',
      }
    },
    openPublishConfirm(id) {
      this.show = true
      this.modalOptions = {
        text: 'Are you sure you want to published this Activity ?',
        okButton: 'published',
        cancelButton: 'Cancel',
        clickedId: id,
        currentAction: 'Status',
      }
    },
    openDeleteConfirm(id) {
      this.show = true
      this.modalOptions = {
        text: 'Are you sure you want to delete this Activity ?',
        okButton: 'Delete',
        cancelButton: 'Cancel',
        clickedId: id,
        currentAction: 'Delete',
      }
    },
    handleModalResult(confirmed) {
      if (!confirmed) {
        this.show = false
        return
      }

      const id = this.modalOptions.clickedId
      if (this.modalOptions.currentAction === 'Delete') {
        this.ActivityService.delete(id)
          .then(() => {
            this.$destroy()
            this.$el.parentNode.removeChild(this.$el)
          })
          .catch(err => {
            console.log(err)
          })
      }

      if (this.modalOptions.currentAction === 'Status') {
        this.ActivityService.changeStatus(id)
          .then(() => {
            this.$destroy()
            this.$el.parentNode.removeChild(this.$el)
          })
          .catch(err => {
            console.log(err)
          })
      }
      this.show = false
    },
  },
}
</script>
<style>
.card-header {
  border-bottom: 1px solid #eaeaea;
}

.card.card-shadow img {
  max-height: 200px;
}

@media (max-width: 648px) {
  .activity-stats span {
    margin-right: 1em;
  }
}
</style>
