<script>
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'NewSassBanner',
  components: {
    BAlert,
  },
  data() {
    return {
      newSaaSHost: process.env.VUE_APP_NEW_SAAS_URL,
      showBanner: false,
    }
  },
  mounted() {
    this.$posthog.onFeatureFlags(() => {
      if (
        this.$store.state.auth.user.has_saas_access &&
        !this.$posthog.isFeatureEnabled('allow-legacy-activities')
      ) {
        this.showBanner = true
      }
    })
  },
}
</script>

<template>
  <b-alert :show="showBanner" variant="warning">
    <div class="saas_access_banner">
      <h4 class="saas_access_banner_title">{{ $t('saas_access_banner_title') }}</h4>
      <p class="saas_access_banner_content">{{ $t('saas_access_banner_content') }}</p>
      <a target="_blank" :href="newSaaSHost" class="saas_access_banner_cta">{{
        $t('saas_access_banner_cta')
      }}</a>
    </div>
  </b-alert>
</template>

<style scoped>
.saas_access_banner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.saas_access_banner_title {
  color: #ff9f43 !important;
}

.saas_access_banner_content {
  color: #ff9f43 !important;
}

.saas_access_banner_cta {
  color: #ff9f43 !important;
  padding: 12px 16px;
  border: 1px solid #ff9f43;
  width: fit-content;
  text-align: center;
  border-radius: 4px;
}
</style>
